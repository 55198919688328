/* src/App.js */
import React, { useEffect, useState } from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { createTodo } from './graphql/mutations'
import { listTodos } from './graphql/queries'
import { withAuthenticator, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from "./aws-exports";
import { Button } from "@blueprintjs/core";
import { Cell, Column, Table } from "@blueprintjs/table";

Amplify.configure(awsExports);

const initialState = { name: '', description: '' }

function App({ signOut, user }) {
    // Todo logic here
    const [formState, setFormState] = useState(initialState)
    const [todos, setTodos] = useState([])

    useEffect(() => {
	fetchTodos()
    }, [])

    function setInput(key, value) {
	setFormState({ ...formState, [key]: value })
    }

    async function fetchTodos() {
	try {
	    const todoData = await API.graphql(graphqlOperation(listTodos))
	    const todos = todoData.data.listTodos.items
	    setTodos(todos)
	} catch (err) { console.log('error fetching todos') }
    }

    async function addTodo() {
	try {
	    if (!formState.name || !formState.description) return
	    const todo = { ...formState }
	    setTodos([...todos, todo])
	    setFormState(initialState)
	    await API.graphql(graphqlOperation(createTodo, {input: todo}))
	} catch (err) {
	    console.log('error creating todo:', err)
	}
    }



    const styles = {
	container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
	todo: {  marginBottom: 15 },
	input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
	todoName: { fontSize: 20, fontWeight: 'bold' },
	todoDescription: { marginBottom: 0 },
	button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
    }
    const rowPlaceholder = (rowIndex: number) => (
	<Cell>{`${(rowIndex * 10).toFixed(2)}`}</Cell>
    );
    const kiloWattCalculator = (rowIndex: number) => (
	<Cell>{`${(rowIndex * 1000).toFixed(2)}`}</Cell>
    );
    return (
	<>
	    <Heading level={1}>Hello {user.attributes.email}</Heading>
	    <Button onClick={signOut}>Sign out</Button>
	    <Table numRows={30}>
		<Column name="kWh" cellRenderer={rowPlaceholder}/>
		<Column name="Wh" cellRenderer={kiloWattCalculator}/>
		<Column name="Aur Bolo"/>
	    </Table>
	    {/*
		<div style={styles.container}>
		    <h2>Project Todos</h2>
		    <input
			onChange={event => setInput('name', event.target.value)}
			style={styles.input}
			value={formState.name}
			placeholder="Name"
		    />
		    <input
			onChange={event => setInput('description', event.target.value)}
			style={styles.input}
			value={formState.description}
			placeholder="Description"
		    />
		    <button style={styles.button} onClick={addTodo}>Create Todo</button>
		    {
			todos.map((todo, index) => (
			    <div key={todo.id ? todo.id : index} style={styles.todo}>
				<p style={styles.todoName}>{todo.name}</p>
				<p style={styles.todoDescription}>{todo.description}</p>
			    </div>
			))
		    }
		</div>
	    */}
	</>
    );
}

export default withAuthenticator(App);
